/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { slug } from '@mangoart/gatsby-ui/helper/Slugger';

import * as styles from './ContentBlock.module.css';

ContentBlock.propTypes = {
  data: PropTypes.shape({
    align: PropTypes.string,
    columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
  }),
};

ContentBlock.defaultProps = {
  data: null,
};

/**
 * @return {null} || React.Node
 */
function ContentBlock({ data }) {
  // data and at least of of the properties image, title or text must be set
  // otherwise the block is not displayed. Even then it is not guarantueed to
  // look good.
  if (!data || !(data.image || data.title || data.text)) {
    return null;
  }

  const { title, text, image } = data;
  const processedText =
    text && text.split('href="/storage').join('href="https://frauennetzwerk.cms.mangomedia.at/storage');
  const align = data.align || 'links';
  const columns = parseInt(data.columns, 10) || 1;

  const blockClassNames = clsx(
    'defaultWidth',
    styles.ContentBlock,
    styles[`align${align}`],
    styles[`column${columns}`],
    {
      [styles.noImage]: !data.image,
    },
  );

  return (
    <div className={blockClassNames}>
      <div>
        <span className="anchor" id={slug(title)} />
      </div>
      <div className={align === 'Rechts' ? styles.headingContainerRight : styles.headingContainer}>
        {title && <h2>{title}</h2>}
      </div>
      {text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: processedText }} />}
      {image && (
        <div className={styles.image}>
          <GatsbyImage image={getImage(image)} />
        </div>
      )}
    </div>
  );
}

export default ContentBlock;
