// extracted by mini-css-extract-plugin
export var ContentBlock = "ContentBlock-module--ContentBlock--gkbna";
export var alignLinks = "ContentBlock-module--alignLinks--o5G1e";
export var alignRechts = "ContentBlock-module--alignRechts--J8wrg";
export var alignZentriert = "ContentBlock-module--alignZentriert--go-Zu";
export var column2 = "ContentBlock-module--column2--bo2s5";
export var column3 = "ContentBlock-module--column3--1c5VL";
export var headingContainer = "ContentBlock-module--headingContainer--odJtY";
export var headingContainerRight = "ContentBlock-module--headingContainerRight--fEp+B";
export var image = "ContentBlock-module--image--T6n6l";
export var noImage = "ContentBlock-module--noImage--tPsnA";
export var text = "ContentBlock-module--text--4Wpjb";